import React from "react";
import {
  Wrap,
  Link,
  WrapItem,
  Stack,
  Avatar,
  // AvatarBadge,
  // AvatarGroup,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

function DonutFooter2() {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <div>
        {/* <div
          id="graph"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "5vh auto",
          }}
        >

        </div> */}
        <Stack mt="5" mb="5" direction="row" spacing={2}>
          <Wrap>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="purple.700"
                  name="Core Resources "
                  size="lg"
                  src="https://bafybeie2dvx4g7ky46aeby7trsdh76v5rdmqnx2kxwytaytvtr322af3kq.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="purple.700"
                  name="Developer Journey"
                  size="lg"
                  src="https://bafybeieu2fksovnnnba66vfcnwr5z3dtrqyfeto6b5ev4estxh4ooblh4a.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="purple.700"
                  name="Decentralized Identity"
                  size="lg"
                  src="https://bafybeia6d44uuppmewz5qk4mfvbm36xmcvkjla2oolti6mq25yedumdkha.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="purple.700"
                  name="DAO Journey"
                  size="lg"
                  src="https://bafybeiednigycn3wz5tsiev7o4dlqozszakarejk3lpdqupocwp7bppdqi.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="purple.700"
                  name="Design Tools"
                  size="lg"
                  src="https://bafybeidjy5sggf7sxajqdcqtbqa73zlvw265ci3xcbuko7rvjmodgmjomi.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="purple.700"
                  name="DeFi Journey"
                  size="lg"
                  src="https://bafybeihq6z3hlvxm5alqjlkzc2rk6yudelqco5rjl47pqa62ihkagc6vay.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="purple.700"
                  name="Meta-Full-Power"
                  size="lg"
                  src="https://bafybeicvtgehifhdl2clysrm7b3fx6g4t5penvurk5mqnyjpzsavcf2omm.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="purple.700"
                  name="Blockchains and Nodes"
                  size="lg"
                  src="https://bafybeig5wsad5vr4cvpop4kxtsegnj32l2gzwyv3423j6qiz7msbpakexy.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="purple.700"
                  name="Legal-Tech"
                  size="lg"
                  src="https://bafybeigdmv372e6g2t6zadlmo3pgrv7nytvakewlmaeia3lan22zrozjlq.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="purple.700"
                  name="Design Assets"
                  size="lg"
                  src="https://bafybeibb44jnhezb5dxaj2utb7wn3g6jecyec5s67pitcfwvwq4ukccwaa.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="purple.700"
                  name="General Content"
                  size="lg"
                  src="https://bafybeid4omle2kc3fz7vyhmr4dtxqmibrchjm3sqwlcesbowumwvg34wpy.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="blue.700"
                  name="General Content 12"
                  size="lg"
                  src="https://bafybeieconmygis5hngaklzeudjf3va74qugurkk6hel5ht5jmoviw73vi.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="blue.700"
                  name="General Content 13"
                  size="lg"
                  src="https://bafybeifnd23pp3j7bob5bzyvx6xyqb5e5p7m5kaec44g4ynkrr7dqvr274.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="blue.700"
                  name="General Content 14"
                  size="lg"
                  src="https://bafybeiezdie2rgs2mmduxyqndzot2gpouzuffzdhwfzq6u3jk77r2xb4vi.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="blue.700"
                  name="General Content 15"
                  size="lg"
                  src="https://bafybeie7xm6k4wvx5hty3ntnrhu2z4f76ghbzloyosx5o6mcm2dyg2pqtq.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="blue.700"
                  name="General Content 16"
                  size="lg"
                  src="https://bafybeiagrpngzhnlkioskva6dbmgnwpvogj5dii2sxu3iizzhuwij6bnze.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="blue.700"
                  name="General Content 17"
                  size="lg"
                  src="https://bafybeigzq7fledajndcfiim4yx25nds4zw4uhe474r2lo7cygtcntl2ef4.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="blue.700"
                  name="General Content 18"
                  size="lg"
                  src="https://bafybeibzzsim2oowzlwlkflwb65zmlijzij762xtmwreo5j63rmed3acrm.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="blue.700"
                  name="General Content 19"
                  size="lg"
                  src="https://bafybeigkiorpwhdjboag23svqdfm5lwdhtxeendswnvizthwvqhg33itea.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
            <WrapItem>
              <Link href="https:/web3kills.com" target="_blank">
                <Avatar
                  bg="blue.700"
                  name="General Content 20"
                  size="lg"
                  src="https://bafybeid5sz5lnsrfxuzozzme7ykjrcnh2n55u2427bflmgtq7qm6qhx3f4.ipfs.infura-ipfs.io/"
                  href="https:/web3kills.com"
                  target="_blank"
                />
              </Link>
            </WrapItem>
          </Wrap>

          {/* <Avatar>
            <Avatar
              name=" Player One "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <AvatarBadge boxSize="1.25em" bg="green.500" />
          </Avatar> */}

          {/* You can also change the borderColor and bg of the badge */}
          {/* <Avatar>
            <Avatar
              name=" Player One "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <AvatarBadge
              borderColor="papayawhip"
              bg="tomato"
              boxSize="1.25em"
            />
          </Avatar> */}

          {/* <AvatarGroup size="md" max={10}>
            <Avatar
              name=" Player One "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player Two "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player Three "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player Four "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player Five "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player Six "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player Seven "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player Eight "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player Nine "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player Five "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player One "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player Two "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player Three "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player Four "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
            <Avatar
              name=" Player Five "
              src=" https://bafybeiaqqimmd5e7n4xxewelyt76hscsxfbfgifxbwitk6lwzbziivn2fq.ipfs.infura-ipfs.io/ "
            />
          </AvatarGroup> */}
        </Stack>
      </div>
    </div>
  );
}
export default DonutFooter2;
