import React from "react";
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps["marginTop"];
}
const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={"md"} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};
interface BlogAuthorProps {
  date: Date;
  name: string;
}
export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src=" https://bafybeiadlckn4fdpst6sgl72yhil7o2v5nvb7ao6ystpovnqk6ictughky.ipfs.infura-ipfs.io/ "
        alt={` Avatar of ${props.name} `}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text> — </Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};
const Blog1 = () => {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <Container maxW={"7xl"} p="12">
        <Heading as="h1"> Web3Skills Collaborative Gigs </Heading>
        <Box
          marginTop={{ base: "1", sm: "5" }}
          display="flex"
          flexDirection={{ base: "column", sm: "row" }}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center"
          >
            <Box
              width={{ base: "100%", sm: "85%" }}
              zIndex="2"
              marginLeft={{ base: "0", sm: "5%" }}
              marginTop="5%"
            >
              <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                <Image
                  borderRadius="lg"
                  src={
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                    // " https://bafybeihyswfympur6dq4qswdfq5kwb6e5a2wmi4t4gvxbmxksxhkyc4mhy.ipfs.infura-ipfs.io/ "
                    // " https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                    // " https://bafybeia5nuk33s2e7szg2wb6fewqs4xmae3r2r7yd27ouoiaymhz3m2j6y.ipfs.infura-ipfs.io/ "
                    // " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
                    // " https://bafybeid2lgmvtnxcz7ivszvxne5jfabpcy62ff6k5t3jracs46gwpvag2m.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiawt6vzzsvjjfpzsggjkt5s2gtapn5lg3gl67xxdis25kzv7frkk4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiakbeu5a6g4jhrvurbamc75q6d2yoo23ihjguk7va4bdnvdfa5bhe.ipfs.infura-ipfs.io/ "
                    // " https://bafybeig6ya4h6ozshcm5tcad5i2aklumdhokzobs4l3xjq7q4gwvg5idcu.ipfs.infura-ipfs.io/ "
                    // " https://bafybeicrnln2gm2a5ivbeeepdupdeg33gzzboxw3smo3ssgtx2xoxrrfoe.ipfs.infura-ipfs.io/ "
                    // " https://bafybeicjsmb535kxrkwdsfqm7oruiionzf2yvhwl2fxhwvf2j756zkfwcq.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidqzoh3flsxoyy264653ctvvero6k52eyrlp6v2qhpk57dacfktb4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeifqq4uwoiw2dc5cqimpic3wjp5zhm6uyyuxmspuvvh4kvu74dlyta.ipfs.infura-ipfs.io/ "
                    // " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
                    // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
                    // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
                    // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
                    // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
                    // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
                    // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
                    // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
                    // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
                    // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
                    // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
                    // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
                    // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"

                    // " https://bafybeic7pou47tnpfxin2fumjnvur7ae3asw7u6cxwz5ehkapoxl2lv7d4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeibxy67mzalhgohwz2vcpqcktaq47ap2onoqh2saykjcsijoln3vmy.ipfs.infura-ipfs.io/ "
                    " https://bafybeibdkqo35uc322jlko3zyjojv4xlpyxpygjecgr6urux5th6cnbxju.ipfs.infura-ipfs.io/ "
                    // " https://bafybeib5jf3ros7klylxleqj4q72ka5rgykflabdsqo36a3obx2wjixpp4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiaafj4jnp66uouhgueeehy4l47v75u6lz4wfquzib7hfpwezpayhi.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidkmtyetadwa5cfzqdbm43shfwrpgkqrbtktaw6qguu4zqisuhgu4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeia2eitu2ss4fzjn42trtu3z6r6dhhwgvkhzfde3qruobiremfbwpa.ipfs.infura-ipfs.io/ "
                    // " https://bafybeib4so2n7gggopq476kjgfx2o7env63wqzhbw7tr3npj2hq7galol4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeifiaoxjhfzqyfk3stxb6qfzip5dfqvtap4rrpd5o7ur5y2v33n6ei.ipfs.infura-ipfs.io/ "
                    // " https://bafybeicyij2rnun7jiphodqyutvycm64hhs2d3zm4m5evuqsca5zujboge.ipfs.infura-ipfs.io/ "
                    // " https://bafybeibphb53hcep3xcnkfuy6gakgutfpfhzfo5gs57sjz2thu4mca4nyi.ipfs.infura-ipfs.io/ "
                    // " https://bafybeihtqc7phwfkddr2kv72wnq3ypby53vjyccuvdwfiuep6oo7pfgoqe.ipfs.infura-ipfs.io/ "
                    // " https://bafybeic7lojs3dpwliwgiat6ctmip3zrw3lafcike4k3ubm4zrzufgwzgi.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiefkmoai22wpsxi47ix64hvx2waxhxfk5jxttamoxy3znfvwjkkgi.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih3nmzbvthww4ecgxqgeardtjnem77d4z4q4tc7qgcblzc7dvvppe.ipfs.infura-ipfs.io/ "
                    // " https://bafybeif36qk2bgwdkqenyr4di3pxg4htvpr44pgqwgga6owki5ks6bebje.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiebnpiqtqjfb3c7fx5fr45em7htjmuay23egmfilhkvckty7d7mhy.ipfs.infura-ipfs.io/ "
                    // " https://bafybeifd3o6suhj6eou5bteyjrgvicdsm3pgzli2tddxtuixczur4vaotu.ipfs.infura-ipfs.io/ "


                  }
                    alt=" MetaCollab "
                  objectFit="contain"
                />
              </Link>
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%">
              <Box
                bgGradient={useColorModeValue(
                  "radial(orange.600 1px, transparent 1px)",
                  "radial(orange.300 1px, transparent 1px)"
                )}
                backgroundSize="20px 20px"
                opacity="0.4"
                height="100%"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: "3", sm: "0" }}
          >
            <BlogTags tags={["Engineering", "Product"]} />
            <Heading as="h2" marginTop="5">
            <Link textDecoration="none" _hover={{ textDecoration: "none" }}>

              Web3Skills Provides Resources and Connects Users to Gigs.
              </Link>
            </Heading>
            <Text
              as="p"
              marginTop="2"
              color={useColorModeValue("white.700", "gray.200")}
              fontSize="lg"
            >
              Web3Skills using MetaCollab provides value and service supporting the fourth
              industrial revolution. Web3 is a set of technologies that publicly
              interoperate through blockchain and node based computing systems.
            </Text>
            <BlogAuthor
              name="Scott Stevenson (tenfinney)"
              date={new Date("2022-02-06")}
            />
          </Box>
        </Box>
        <Divider marginTop="5" />
        <VStack align="center" paddingTop="40px" spacing="2">
          <Heading color="cyan" as="h2">
            {" "}
            Web3Skills using MetaCollab ...{" "}
          </Heading>
          <Heading color="pink" as="h2">
            {" "}
            a complete contracting solution.{" "}
          </Heading>
          <Text as="p" fontSize="lg">
          Web3Skills using MetaCollab provides value and service supporting the fourth industrial
            revolution. Web3 is a set of technologies that publicly interoperate
            through blockchain and node based computing systems.
          </Text>
          <Text as="p" fontSize="lg">
          Web3Skills using MetaCollab provides value and service supporting the fourth industrial
            revolution. Web3 is a set of technologies that publicly interoperate
            through blockchain and node based computing systems.
          </Text>
          <Text as="p" fontSize="lg">
          Web3Skills using MetaCollab provides value and service supporting the fourth industrial
            revolution. Web3 is a set of technologies that publicly interoperate
            through blockchain and node based computing systems.
          </Text>
        </VStack>
      </Container>
    </div>
  );
};
export default Blog1;
