import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";


import { ReactNode } from "react";
import { FaInstagram, FaTwitter, FaGithub, FaDiscord, FaYoutube, FaSearch } from "react-icons/fa";
const Logo = (props: any) => {
  return (

    <Image
      fit={"cover"}
      align={"center"}
      w={"100%"}
      // h={"100px"}
          // src=" https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
          // src=" https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
          // src=" https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
          // src=" https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
          // src=" https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
          // src=" https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
          // src=" https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
          // src=" https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
          // src=" https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
          // src=" https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
          // src=" https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
          // src=" https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
          // src=" https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"
          // src=" https://bafybeic7pou47tnpfxin2fumjnvur7ae3asw7u6cxwz5ehkapoxl2lv7d4.ipfs.infura-ipfs.io/ "

          // src=" https://bafybeibxy67mzalhgohwz2vcpqcktaq47ap2onoqh2saykjcsijoln3vmy.ipfs.infura-ipfs.io/ "
          // src=" https://bafybeibdkqo35uc322jlko3zyjojv4xlpyxpygjecgr6urux5th6cnbxju.ipfs.infura-ipfs.io/ "
          // src=" https://bafybeib5jf3ros7klylxleqj4q72ka5rgykflabdsqo36a3obx2wjixpp4.ipfs.infura-ipfs.io/ "
          // * src=" https://bafybeiaafj4jnp66uouhgueeehy4l47v75u6lz4wfquzib7hfpwezpayhi.ipfs.infura-ipfs.io/ "
          // src=" https://bafybeidkmtyetadwa5cfzqdbm43shfwrpgkqrbtktaw6qguu4zqisuhgu4.ipfs.infura-ipfs.io/ "
          // src=" https://bafybeia2eitu2ss4fzjn42trtu3z6r6dhhwgvkhzfde3qruobiremfbwpa.ipfs.infura-ipfs.io/ "
          // src=" https://bafybeib4so2n7gggopq476kjgfx2o7env63wqzhbw7tr3npj2hq7galol4.ipfs.infura-ipfs.io/ "
          // src=" https://bafybeifiaoxjhfzqyfk3stxb6qfzip5dfqvtap4rrpd5o7ur5y2v33n6ei.ipfs.infura-ipfs.io/ "
          // * src=" https://bafybeicyij2rnun7jiphodqyutvycm64hhs2d3zm4m5evuqsca5zujboge.ipfs.infura-ipfs.io/ "
          // src=" https://bafybeibphb53hcep3xcnkfuy6gakgutfpfhzfo5gs57sjz2thu4mca4nyi.ipfs.infura-ipfs.io/ "
          // src=" https://bafybeihtqc7phwfkddr2kv72wnq3ypby53vjyccuvdwfiuep6oo7pfgoqe.ipfs.infura-ipfs.io/ "
          // src=" https://bafybeic7lojs3dpwliwgiat6ctmip3zrw3lafcike4k3ubm4zrzufgwzgi.ipfs.infura-ipfs.io/ "
          // src=" https://bafybeiefkmoai22wpsxi47ix64hvx2waxhxfk5jxttamoxy3znfvwjkkgi.ipfs.infura-ipfs.io/ "
          // src=" https://bafybeih3nmzbvthww4ecgxqgeardtjnem77d4z4q4tc7qgcblzc7dvvppe.ipfs.infura-ipfs.io/ "
          src=" https://bafybeif36qk2bgwdkqenyr4di3pxg4htvpr44pgqwgga6owki5ks6bebje.ipfs.infura-ipfs.io/ "
          // src=" https://bafybeiebnpiqtqjfb3c7fx5fr45em7htjmuay23egmfilhkvckty7d7mhy.ipfs.infura-ipfs.io/ "
          // * src=" https://bafybeifd3o6suhj6eou5bteyjrgvicdsm3pgzli2tddxtuixczur4vaotu.ipfs.infura-ipfs.io/ "

        alt=" Web3Skills "
    />
  );
};

const Logo1 = (props: any) => {
  return (

    <Image
      alt={" Web3Skills-Smartlaw "}
      fit={"cover"}
      align={"center"}
      maxW={"200px"}
      // w={"20%"}
      // h={"60px"}
      src={" https://bafybeifujp5l4im52bmshxzwcdisboo74h45xl43dskc5r3au6mjsxopaq.ipfs.infura-ipfs.io "


      }
    />
  );
};




const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode,
  label: string,
  href: string,
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blue", "purple")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("purple", "blue"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};
const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text color={"white"} fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};
function Footer6() {
 
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
    <Box
      // color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container as={Stack} maxW={"6xl"} py={4}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 2fr" }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Logo color={useColorModeValue("gray.200", "white")} />
            </Box>
            <Text  fontSize={"sm"}></Text>
            <Stack direction={"row"} spacing={6}>
              <SocialButton target="_blank" label={"Twitter"} href={" https://twitter.com/blackboxlaw "}>
                <FaTwitter />
              </SocialButton>
              <SocialButton target="_blank" label={"Github"} href={" https://github.com/blackboxlaw "}>
                <FaGithub />
              </SocialButton>
              <SocialButton target="_blank" label={"Discord"} href={" https://discordapp.com/users/tenfinney "}>
                <FaDiscord />
              </SocialButton>
              <SocialButton target="_blank" label={"YouTube"} href={" https://discordapp.com/users/tenfinney "}>
                <FaYoutube />
              </SocialButton>
              <SocialButton target="_blank" label={"Instagram"} href={" https://discordapp.com/users/tenfinney "}>
                <FaInstagram />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader> <Text color={"gray.200"} fontSize="xl" fontWeight={"semibold"} >Web3Skills </Text></ListHeader>
            <Link color={"white"} target="_blank" href={"https://promo.metacollab.freeweb3.com"}> About Web3Skills </Link>
            <Link color={"white"} target="_blank" href={"https://query.metacollab.freeweb3.com"}> Query Gigs </Link>
            <Link color={"white"} target="_blank" href={"https://images.metacollab.freeweb3.com"}> Fund a Gigs </Link>
            <Link color={"white"} target="_blank" href={"https://player.metacollab.freeweb3.com"}> Find a Doer </Link>
            <Link color={"white"} target="_blank" href={"https://userguide.metacollab.freeweb3.com"}> User Guide </Link>
            <Link color={"white"} target="_blank" href={"https://buildagig.metacollab.freeweb3.com"}> Build a Gig </Link>
            <Link color={"white"} target="_blank" href={"https://appgallery.metacollab.freeweb3.com"}> Knowledge Base </Link>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader> <Text color={"gray.200"} fontSize="xl" fontWeight={"semibold"}> Support </Text> </ListHeader>
            <Link color={"white"} target="_blank" href={"https://demos.metacollab.freeweb3.com"}> Demonstrations </Link>
            <Link color={"white"} target="_blank" href={"https://cards.metacollab.freeweb3.com"}> Content Templates </Link>
            <Link color={"white"} target="_blank" href={"https://userguide.metacollab.freeweb3.com"}> Terms of Service </Link>
            <Link color={"white"} target="_blank" href={"https://userguide.metacollab.freeweb3.com"}> Privacy Policy </Link>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader> <Text color={"cyan"} fontWeight={"semibold"}> Search Web3Skills Gigs</Text></ListHeader>
            <Stack direction={"row"}>
              <Input
              color="gray.800"
                placeholder={" Your 0x account address"}
                bg={useColorModeValue("blackAlpha.100", "whiteAlpha.500")}
                border={0}
                _focus={{
                  bg: "gray.300",
                }}
              />
              <IconButton
                bg={useColorModeValue("green.400", "green.300")}
                color={useColorModeValue("white", "gray.800")}
                _hover={{
                  bg: "green.100",
                }}
                aria-label=" connect-account "
                icon={<FaSearch />}
              />
            </Stack>
          </Stack>
        </SimpleGrid>

        <Box
      // bg={useColorModeValue("gray.400", "gray.900")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Logo1 />
        <Text color="gray.200"> © 2022 W3LE. All rights reserved </Text>
        
        <Stack direction={"row"} spacing={6}>
              <SocialButton color="gray.200" target="_blank" label={"Twitter"} href={" https://twitter.com/blackboxlaw "}>
                <FaTwitter />
              </SocialButton>
              <SocialButton color="gray.200" target="_blank" label={"Github"} href={" https://github.com/blackboxlaw "}>
                <FaGithub />
              </SocialButton>
              <SocialButton color="gray.200" target="_blank" label={"Discord"} href={" https://discordapp.com/users/tenfinney "}>
                <FaDiscord />
              </SocialButton>
              <SocialButton color="gray.200" target="_blank" label={"YouTube"} href={" https://twitter.com/blackboxlaw "}>
                <FaYoutube />
              </SocialButton>
              <SocialButton color="gray.200" target="_blank" label={"Instagram"} href={" https://twitter.com/blackboxlaw "}>
                <FaInstagram />
              </SocialButton>
            </Stack>
      </Container>
    </Box>


      </Container>




      
    </Box>
    </ div>

);
}
export default Footer6;
